<template>
  <div>
    <div class="row">
      <custom-drop-zone :name="`rightPicDropZone${index}`"
                        @setFile="setFile($event)"
                        :uploaded-blob="content.blob && content.blob.length ? content.blob[0] : null"/>

      <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" @input="setBlockContentData($event, 'serverData')" v-model="serverData"></ckeditor>
    </div>

    <p>
      Англ
    </p>

    <ckeditor :config="$editorConfigEng" :editor-url="$root.editorUrl" @input="setBlockContentData($event, 'serverDataEng')" v-model="serverDataEng"></ckeditor>
  </div>
</template>

<script>
import CustomDropZone from '../CustomDropZone'

export default {
  name: 'LeftPicContentBlock',
  props: {
    index: {
      type: Number,
      required: true
    },
    content: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      image: [null],
      serverData: null,
      serverDataEng: null
    }
  },
  watch: {
    content: {
      immediate: true,
      handler () {
        this.serverData = this.content.text
        this.serverDataEng = this.content.text_eng
      }
    }
  },
  methods: {
    setBlockContentData (value, field) {
      this[field] = value

      this.$emit('setBlockContentData', {
        index: this.index,
        data: {
          text: this.serverData,
          text_eng: this.serverDataEng,
          image: this.image
        }
      })
    },

    setFile (file) {
      this.image[0] = file
      this.setBlockContentData(this.serverData)
    }
  },
  components: {
    CustomDropZone
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;

    > .row {
      margin-bottom: 1.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  textarea {
    width: 38.437rem !important;
    height: 100%;
    min-height: 6.375rem;
  }

</style>
