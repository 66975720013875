<template>
  <div>
    <div class="row">
      <custom-drop-zone :name="`leftPicDropZone${index}`"
                        @setFile="setFile($event, 0)"
                        :uploaded-blob="content.blob && content.blob.length ? content.blob[0] : null"/>

      <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" @input="setBlockContentData($event, 'serverData')" v-model="serverData"></ckeditor>

      <custom-drop-zone :name="`rightPicDropZone${index}`"
                        @setFile="setFile($event, 1)"
                        :uploaded-blob="content.blob && content.blob.length > 1 ? content.blob[1] : null"/>
    </div>

    <p>
      Англ
    </p>

    <ckeditor :config="$editorConfigEng" :editor-url="$root.editorUrl" @input="setBlockContentData($event, 'serverDataEng')" v-model="serverDataEng"></ckeditor>

  </div>
</template>

<script>
import CustomDropZone from '../CustomDropZone'

export default {
  name: 'TwoPicContentBlock',
  props: {
    index: {
      type: Number,
      required: true
    },
    content: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      images: [null, null],
      serverData: null,
      serverDataEng: null
    }
  },
  watch: {
    content: {
      immediate: true,
      handler () {
        this.serverData = this.content.text
        this.serverDataEng = this.content.text_eng
      }
    }
  },
  methods: {
    setBlockContentData (value, field) {
      if (field) {
        this[field] = value
      }

      this.$emit('setBlockContentData', {
        index: this.index,
        data: {
          text: this.serverData,
          text_eng: this.serverDataEng,
          image: this.images
        }
      })
      this.$forceUpdate()
    },

    setFile (file, index) {
      this.images[index] = file
      this.setBlockContentData(this.serverData, 'serverData')
    }
  },
  components: {
    CustomDropZone
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;

    > .row {
      margin-bottom: 1.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  textarea {
    width: 25.437rem !important;
    height: 100%;
    min-height: 6.375rem;
  }

</style>
