<template>
  <div>
    <section v-for="(file, i) in files" :key="`${i}gallery${index}`">
      <custom-drop-zone
              :name="`${i}gallery${index}`"
              @setFile="setFile($event, i)"
              :uploaded-blob="content.blob && content.blob[i] ? content.blob[i] : null"
              :file-prop="file"/>

      <div class="delete_file" @click="deleteFile(i)">
        X
      </div>
    </section>
    <img src="/assets/icons/circle_plus.svg" @click="addFile">
  </div>
</template>

<script>
import CustomDropZone from '../CustomDropZone'

export default {
  name: 'GalleryContentBlock',

  props: {
    index: {
      type: Number,
      required: true
    },
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      files: [null]
    }
  },

  watch: {
    content: {
      deep: true,
      immediate: true,
      handler () {
        this.files = new Array(this.content.blob.length)
      }
    }
  },

  methods: {
    setBlockContentData () {
      this.$emit('setBlockContentData', {
        index: this.index,
        data: {
          image: this.files
        }
      })
      this.$forceUpdate()
    },

    setFile (file, index) {
      this.files[index] = file
      this.setBlockContentData()
    },

    addFile () {
      this.files.push(null)
    },

    deleteFile (i) {
      // this.files = this.files.slice(i, 1)
      // this.files.splice(i, 1)
      this.$emit('deleteFile', {
        contentBlockIndex: this.index,
        fileIndex: i
      })
      console.debug(this.files)
      this.$forceUpdate()
    }
  },

  components: {
    CustomDropZone
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  div {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;

    > * {
      margin-right: 1rem;
    }

    > section {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .delete_file {
        margin-top: .5rem;
        cursor: pointer;
      }
    }

    img {
      cursor: pointer;
      width: 5rem;
      height: 2rem;
      object-fit: contain;
      object-position: center;
      margin-right: 1rem;
    }
  }

</style>
