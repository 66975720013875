<template>
  <article @click="setContentBlockType" :class="{active: index === currentContentBlockType}">
    <img :src="getCardIcon">
    <p>{{ contentBlockType.title }}</p>
  </article>
</template>

<script>
export default {
  name: 'ContentBlockCard',
  props: {
    contentBlockType: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    currentContentBlockType: {
      type: Number,
      required: true
    }
  },
  methods: {
    setContentBlockType () {
      this.$emit('setContentBlockType', this.index)
    }
  },
  computed: {
    getCardIcon () {
      return `/assets/icons/${this.contentBlockType.code.toLowerCase()}.svg`
    }
  }
}
</script>

<style scoped lang="scss">
  article {
    background-color: white;
    border-radius: .1875rem;
    width: 9.5rem;
    height: 7.5rem;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid white;
    transition: .35s;

    img {
      width: 6.5625rem;
      height: 2.0625rem;
      margin-bottom: 1.25rem;
      object-fit: contain;
      object-position: center;
    }

    > p {
      font-size: .75rem;
      line-height: .875rem;
      color: #5A79A8;
      width: 8.25rem;
      text-align: center;
    }

    &.active {
      border: 1px solid #5A79A8;
    }
  }
</style>
