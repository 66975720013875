<template>
  <div>
    <ckeditor :config="editorConfig" :editor-url="$root.editorUrl" @input="setBlockContentData($event, 'serverData')" v-model="serverData"></ckeditor>
    <br/>
    <p>
      Англ
    </p>
    <ckeditor :config="editorConfigEng" :editor-url="$root.editorUrl" @input="setBlockContentData($event, 'severDatEng')" v-model="serverDataEng"></ckeditor>
  </div>
</template>

<script>
export default {
  name: 'TextContentBlock',
  props: {
    index: {
      type: Number,
      required: true
    },
    content: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      editorConfig: {
        extraAllowedContent: 'summary details'
      },
      editorConfigEng: {
        extraAllowedContent: 'summary details'
      },
      serverData: null,
      serverDataEng: null
    }
  },

  watch: {
    content: {
      immediate: true,
      handler () {
        this.serverData = this.content.text
        this.serverDataEng = this.content.text_eng
      }
    }
  },

  methods: {
    setBlockContentData (value, field) {
      this[field] = value

      this.$emit('setBlockContentData', {
        index: this.index,
        data: {
          text: this.serverData,
          text_eng: this.serverDataEng
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  textarea {
    width: 100% !important;
    height: 100%;
    min-height: 6.375rem;
  }
</style>
