import { render, staticRenderFns } from "./LeftPicContentBlock.vue?vue&type=template&id=30c54fa8&scoped=true&"
import script from "./LeftPicContentBlock.vue?vue&type=script&lang=js&"
export * from "./LeftPicContentBlock.vue?vue&type=script&lang=js&"
import style0 from "./LeftPicContentBlock.vue?vue&type=style&index=0&id=30c54fa8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30c54fa8",
  null
  
)

export default component.exports